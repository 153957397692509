<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="文件名称">
              <a-input
                v-model:value.trim="where.fileName"
                placeholder="文件名称"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="id"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
        @change="onTableChange"
      >
        <template #toolbar>
          <a-space>
            <a-button type="primary" @click="openEdit()">
              <template #icon>
                <plus-outlined />
              </template>
              <span>新建</span>
            </a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <template #img="{ record }">
          <img :src="record.goodsMainPic" width="50" height="50" />
        </template>
        <template #download="{ record }">
          <!-- <a-tag color="geekblue" class="tag" @click="downloadClick(record.fileId)">
            下载
          </a-tag> -->
          <a :href="href" target="_blank" @click="downloadClick(record)">查看文件</a>
        </template>
        <template #action="{ record }">
          <a-space>
            <!-- <a @click="openDetail(record)">详情</a> -->
            <a @click="openEdit(record)">编辑</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确定要删除此项吗？" @confirm="remove(record)">
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 编辑 -->
    <FileEdit v-model:visible="showEdit" :data="current" @done="reload" />
    <!-- 详情弹窗 -->
    <!-- <QuestionInfoDetail v-model:visible="showDetail" :data="current || {}" /> -->
  </div>
</template>

<script>
import * as fileApi from '@/api/jyyw/file'
import * as heroInfoApi from '@/api/jyyw/heroInfo'
import FileEdit from './index-edit.vue'
// import QuestionInfoDetail from './product-detail.vue'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons-vue'
export default {
  name: 'navigationIndex',
  components: {
    PlusOutlined,
    DeleteOutlined,
    FileEdit
    // QuestionInfoDetail
  },
  data() {
    return {
      // 表格列配置
      columns: [
        {
          title: '文件名称',
          dataIndex: 'fileName'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '查看',
          slots: { customRender: 'download' }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: '150px',
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格排序条件
      order: {},
      // 表格选中数据
      selection: [],
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示详情弹窗
      showDetail: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false,
      // 是否显示用户导入弹窗
      showImport: false,
      categoryList: [],
      // 英雄信息名称list
      heroInfoList: [],
      // 商品名称list
      sortList: [],
      // 状态字典项
      goodsStatus: [],
      href: ''
    }
  },
  created() {
    this.queryheroInfoList()
  },
  methods: {
    datasource(option, callback) {
      fileApi
        .page({
          ...this.where,
          ...this.order,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            res.data.map((item) => {
              // if (item.goodsStatus === 0) {
              //   item.goodsStatusName = '不显示'
              // } else if (item.goodsStatus === 1) {
              //   item.goodsStatusName = '显示'
              // }
            })

            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 表格分页、排序、筛选改变回调 */
    onTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.order.sort = sorter.field
        this.order.order = { ascend: 'asc', descend: 'desc' }[sorter.order]
      } else {
        this.order = {}
      }
      // this.reload()
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    downloadClick(type) {
      this.href = type.filePath
    },
    // /* 删除单个 */
    // remove(row) {
    //   const hide = this.$message.loading('请求中..', 0)
    //   fileApi
    //     .deleteById(row.id)
    //     .then((res) => {
    //       hide()
    //       if (res.code === 0) {
    //         this.$message.success(res.msg)
    //         this.reload()
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       hide()
    //       this.$message.error(e.message)
    //     })
    // },
    // /* 批量删除 */
    // removeBatch() {
    //   if (!this.selection.length) {
    //     this.$message.error('请至少选择一条数据')
    //     return
    //   }
    //   this.$confirm({
    //     title: '提示',
    //     content: '确定要删除选中的项吗?',
    //     maskClosable: true,
    //     onOk: () => {
    //       const hide = this.$message.loading('请求中..', 0)
    //       const data = { data: this.selection.map((d) => d.id) }
    //       fileApi
    //         .deleteBatch(data)
    //         .then((res) => {
    //           hide()
    //           if (res.code === 0) {
    //             this.$message.success(res.msg)
    //             this.reload()
    //           } else {
    //             this.$message.error(res.msg)
    //           }
    //         })
    //         .catch((e) => {
    //           hide()
    //           this.$message.error(e.message)
    //         })
    //     }
    //   })
    // },
    /* 打开编辑弹窗 */
    openEdit(row) {
      console.log('row', row)
      this.current = row
      this.showEdit = true
    },
    /* 详情 */
    openDetail(row) {
      console.log('row', row)
      this.current = row
      this.showDetail = true
    },
    // 获取英雄名称List
    queryheroInfoList() {
      heroInfoApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.heroInfoList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style scoped>
.tag {
  cursor: pointer;
}
</style>
