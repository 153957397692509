<!-- 用户编辑弹窗 -->
<template>
  <a-modal :width="580" :visible="visible" :confirm-loading="loading" :title="isUpdate ? '修改菜单' : '新建菜单'"
    :body-style="{ paddingBottom: '8px' }" @update:visible="updateVisible" @ok="save">
    <a-form ref="form" :model="form" :rules="rules" :label-col="{ md: { span: 7 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }">
      <a-row :gutter="16">
        <a-col :md="20" :sm="24" :xs="24">
          <a-form-item label="文件上传:">
            <a-upload v-model:file-list="fileList" :remove="removeFile" :customRequest="({ file }) => uploadFile(file)"
              @change="handleChange">
              <div v-if="fileList.length < 1">
                <a-button>
                  <upload-outlined></upload-outlined>
                  上传
                </a-button>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item label="文件名称:" name="fileName">
            <a-input allow-clear placeholder="请输入名称" v-model:value="form.fileName" />
          </a-form-item>
          <a-form-item label="英雄人员:" name="heroIds">
            <a-select show-search :showSearch="true" placeholder="请选择英雄人员" v-model:value="form.heroIds" mode="multiple"
              :filterOption="filterOption">
              <a-select-option v-for="item in heroInfoList" :key="item.id" :value="item.id">
                （{{ item.heroNumber }}）{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="备注:" name="remark">
            <a-input allow-clear placeholder="请输入备注" v-model:value="form.remark" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import setting from '@/config/setting'
import * as fileApi from '@/api/jyyw/file'
import * as heroInfoApi from '@/api/jyyw/heroInfo'
import { UploadOutlined } from '@ant-design/icons-vue'
export default {
  name: 'navigationEdit',
  components: {
    UploadOutlined
  },
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      fileList: [],
      uploadUrl: setting.uploadUrl,
      // 表单验证规则
      rules: {},
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 英雄信息名称list
      heroInfoList: [],
      frontDataZ: [], // 存放前100的数据
      valueData: '',
      scrollPage: 1,
      // 状态字典项
      stateDict: [],
      lastFetchId: 0
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data)
        if (this.form.heros) {
          this.heroIds = this.form.heros.split(',')
        }
        if (this.form.filePath) {
          this.fileList = [
            { url: this.form.filePath, name: this.form.fileName }
          ]
        }
        this.queryFileHeroList()
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    this.queryheroInfoList()
  },
  methods: {
    handle(value) {
      console.log('handle', value)
    },
    handleBlur() {
      console.log('handleBlur')
    },
    handleFocus() {
      console.log('focus')
    },
    handleSearch(val, option) {
      console.log('搜素', val)
      console.log(option)
      this.valueData = val
      this.heroInfoList.map((item) => {
        if (item.name.indexOf(val) >= 0) {
          this.frontDataZ.push(item)
        }
      })
    },
    filterOption(input, option) {
      return (
        option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleChange(info) {
      if (info.file.status !== 'uploading') {
        console.log('uploading', info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        console.log('done')
      } else if (info.file.status === 'error') {
        console.log('error')
      }
    },
    /* 保存编辑 */
    save() {
      if (this.fileList) {
        this.form.filePath = this.fileList[0].url
      } else {
        this.form.filePath = ''
      }
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          fileApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                console.log(res.code)
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                  this.fileList = []
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => { })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 删除图片
    removeFile(file) {
      console.log(file)
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.form.fileList = []
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    // 上传图片
    uploadFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            this.fileList[0] = {
              uid: '-4',
              name: res.data.fileName,
              status: 'done',
              url: res.data.location
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    // 获取英雄名称List
    queryheroInfoList() {
      heroInfoApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.heroInfoList = res.data
            this.frontDataZ = this.heroInfoList.slice(0, 100)
            console.log('frontDataZ', this.frontDataZ)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 获取文件英雄人员
    queryFileHeroList() {
      fileApi
        .heroIds({ fileId: this.form.fileId })
        .then((res) => {
          if (res.code === 0) {
            this.form.heroIds = res.data.map((item) => item.heroId)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style scoped>
</style>
